// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

.form-select {
  display: block;
  width: 100%;
  @include text-padding($input-padding-y, $input-padding-x);
  padding-right: $input-padding-y * 2.5;
  font-family: $form-select-font-family;
  @include font-size($form-select-font-size);
  font-weight: $form-select-font-weight;
  line-height: $form-select-line-height;
  border: $form-select-border-width solid $form-select-border-color;
  @include border-radius($form-select-border-radius, 0);
  @include box-shadow($form-select-box-shadow);
  background-color: $form-select-bg;
  background-image: escape-svg($form-select-indicator);
  background-repeat: no-repeat;
  background-position: $form-select-bg-position;
  background-size: $form-select-bg-size;
  color: $form-select-color;
  appearance: none;
  vertical-align: middle;

  &:focus {
    border-color: $form-select-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $form-select-focus-box-shadow;
    }
  }

  &[multiple],
  &[size]:not([size="1"]) {
    padding-right: $form-select-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $form-select-disabled-color;
    background-color: $form-select-disabled-bg;
    border-color: $form-select-disabled-border-color;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $form-select-color;
  }
}

.form-select-sm {
  width: auto;
  @include text-padding($input-padding-y-sm, $input-padding-x-sm);
  padding-right: $input-padding-x-sm * 1.8;
  background-position: $form-select-sm-bg-position;
  // padding-top: $input-padding-y-sm;
  // padding-bottom: $input-padding-y-sm;
  // padding-left: $form-select-padding-x-sm;
  // @include font-size($form-select-font-size-sm);
}

.form-select-lg {
  @include text-padding($input-padding-y-lg, $input-padding-x-lg);
  // padding-top: $input-padding-y-lg;
  // padding-bottom: $input-padding-y-lg;
  // padding-left: $form-select-padding-x-lg;
  // @include font-size($form-select-font-size-lg);
}

// Multi select
.multi-select {
  .multi-select__actor {
    background-color: $input-bg;
  }
}

.multi-select__actor {
  background-image: escape-svg($form-select-indicator);
  background-position: $form-select-bg-position;
  background-repeat: no-repeat;
  background-size: $form-select-bg-size;
  cursor: pointer;
}

.multi-select__item {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: $select-item-padding-y 0;
  color: $body-color-secondary;
  cursor: pointer;

  &:hover {
    color: shift-color($body-color-secondary, 50%);
  }

  &:before {
    content: '';
    display: block;
    width: $form-check-input-width;
    height: $form-check-input-width;
    color: $checkbox-color;
    border: $checkbox-border-width solid currentColor;
    border-radius: 0;
    margin-right: 0.75rem;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: $checkbox-border-width * 2;
    top: 50%;
    transform: translateY(-50%);
    width: $form-check-input-width - $checkbox-border-width * 4;
    height: $form-check-input-width - $checkbox-border-width * 4;
    background-color: transparent;
    border-radius: 0;
  }
}

.mult-select__item_selected {
  color: $checkbox-active-color;

  &:before {
    color: $checkbox-active-color;
  }

  &:after {
    background-color: $checkbox-active-color;
  }
}
