.store-location {
  .page-title {
    font-size: 2.1875rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
  }

  &__search {
    position: relative;
    width: 100%;

    &-input {
      padding: 1.25rem 3.875rem 1rem 1.25rem;
      height: 3.75rem;
      border: 1px solid $border-color;
      font-size: 0.875rem;
      width: 100%;
    }

    &-btn {
      position: absolute;
      right: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
    }

    &-result {
      &__item {
        padding: 1.875rem 0;
        border-top: 1px solid $border-color;

        &:first-child {
          border-top: 0;
        }

        h5 {
          margin-bottom: 0.8125rem;
        }

        a {
          font-size: 0.8125rem;
          line-height: 1.5rem;
          display: inline-block;
          position: relative;
          text-transform: uppercase;
          font-weight: 500;

          &:after {
            content: '';
            display: block;
            border-bottom: 2px solid;
            width: 0;
            position: absolute;
            bottom: -2px;
            left: 0;
            transition: all .3s ease;
          }

          &:hover:after {
            width: 75%;
          }
        }
      }
    }
  }

  .google-map__wrapper {
    height: 46.875rem;
    max-height: 100vh;
  }
}