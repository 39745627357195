.blog {
  &-page {
    &-title {
      width: 112.5rem;
      height: 16rem;
      max-width: calc(100% - 1.875rem);
      border: 2px solid $border-color;
      margin: 0 auto;
      background-size: cover;
      position: relative;
      display: flex;
      align-items: center;
      padding: 1.25rem;

      .page-title {
        @include font-size(3.75rem);
        font-weight: 700;
        text-transform: uppercase;
      }

      .title-bg {
        display: block;
        position: absolute;
        left: 0.5rem;
        right: 0.5rem;
        top: 0.5rem;
        bottom: 0.5rem;
        z-index: -1;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      @include media-breakpoint-up(sm) {
        height: 27.5rem;
        padding: 0;
      }
    }

    .flaticon {
      font-size: 0.625rem;
    }
  }

  &__filter {
    display: flex;
    gap: 1rem;
    row-gap: 0;
    flex-wrap: wrap;

    > a {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  &-grid {
    &__item {
      margin-bottom: 2.75rem;

      &-image {
        margin-bottom: 1.25rem;

        img {
          display: block;
          width: 100%;
        }
      }

      &-meta {
        display: flex;
        gap: 1.5rem;
        text-transform: uppercase;
        color: $secondary;
        margin-bottom: 0.25rem;
        white-space: nowrap;
      }

      &-title {
        font-size: 1.625rem;
        margin-bottom: 1.875rem;
        line-height: 1.5;

        @include media-breakpoint-down(lg) {
          font-size: 1.125rem;
          margin-bottom: 1.25rem;
        }
      }

      &-content {
        p {
          margin-bottom: 0.625rem;
        }

        .readmore-link {
          text-transform: uppercase;
          position: relative;
          display: inline-block;
          font-weight: 500;

          &:after {
            content: "";
            display: block;
            width: 70%;
            border-bottom: 2px solid;
            position: absolute;
            bottom: -2px;
            left: 0;
            transition: all 0.3s ease;
          }

          &:hover:after {
            width: 100%;
          }
        }
      }
    }

    &.row-cols-xl-3 {
      .blog-grid__item-title {
        font-size: 1.125rem;
        margin-bottom: 1.25rem;
      }
    }
  }

  &-list {
    margin-bottom: 3.125rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 6.25rem;
    }

    &__item {
      margin-bottom: 1.875rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.25rem;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
        gap: 3.125rem;
      }

      &-image {
        flex: 0 0 100%;
        width: 100%;

        @include media-breakpoint-up(lg) {
          flex: 0 0 calc(50% - 1.5625rem);
        }

        img {
          display: block;
          width: 100%;
        }
      }

      &-detail {
        flex: 0 0 100%;
        padding: 0;

        @include media-breakpoint-up(lg) {
          flex: 0 0 calc(50% - 1.5625rem);
          padding: 1.875rem 0;
        }
      }

      &-meta {
        display: flex;
        gap: 1.5rem;
        text-transform: uppercase;
        color: $secondary;
        margin-bottom: 0.25rem;
      }

      &-title {
        font-size: 1.125rem;
        margin-bottom: 1.25rem;
        line-height: 1.5;

        @include media-breakpoint-up(lg) {
          font-size: 1.625rem;
          margin-bottom: 1.875rem;
        }
      }

      &-content {
        p {
          margin-bottom: 0.625rem;
        }

        .readmore-link {
          text-transform: uppercase;
          position: relative;
          display: inline-block;
          font-weight: 500;

          &:after {
            content: "";
            display: block;
            width: 70%;
            border-bottom: 2px solid;
            position: absolute;
            bottom: -2px;
            left: 0;
            transition: all 0.3s ease;
          }

          &:hover:after {
            width: 100%;
          }
        }
      }
    }
  }

  &-single {
    .page-title {
      margin-bottom: 0.875rem;
    }

    &__reviews {
      &-title {
        font-size: 1.125rem;
        margin-bottom: 1.75rem;
      }

      &-list {
        display: flex;
        flex-direction: column;
        gap: 1.87rem;
        margin-bottom: 2.375rem;
      }

      &-item {
        display: flex;
        gap: 1.875rem;
        border-bottom: 1px solid $border-color;

        &:last-child {
          border-bottom: 0;
        }

        .customer-avatar {
          flex: 0 0 3.75rem;
          width: 3.75rem;
          height: 3.75rem;
          border-radius: 2rem;
          overflow: hidden;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .customer-review {
          .customer-name {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > h6 {
              margin: 0;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.5rem;
            }
          }

          .review-date {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5rem;
            color: $secondary;
            margin-bottom: 1.25rem;
          }

          .review-text {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5rem;
            color: $secondary;
            margin-bottom: 1.5rem;
          }
        }
      }
    }

    &__review-form {
      form {
        > h5 {
          font-size: 1.125rem;
          margin-bottom: 0.375rem;
        }

        > p {
          font-size: 0.875rem;
          color: $secondary;
          line-height: 1.5rem;
          margin-bottom: 1.875rem;
        }

        .select-star-rating {
          margin-bottom: 2rem;
        }

        button {
          text-transform: uppercase;
          font-size: 0.875rem;
          min-width: 12.5rem;
          height: 3.75rem;
        }
      }
    }

    &__item {
      &-meta {
        display: flex;
        gap: 1.5rem;
        text-transform: uppercase;
        color: $secondary;
        margin-bottom: 2.625rem;
      }

      &-pagination {
        padding-top: 2.8125rem;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        margin-bottom: 3.125rem;

        p {
          margin-bottom: 2.625rem !important;
        }

        a {
          color: $secondary;
        }
      }
    }

    p {
      margin-bottom: 1.875rem;

      img {
        margin-bottom: 3.25rem;
      }
    }

    h5 {
      margin-bottom: 1.875rem;
    }

    .text-list {
      margin-bottom: 1.875rem;

      &__item {
        line-height: 1.875rem;
      }
    }

    &__item-share {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 3.125rem;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }

    .btn-share {
      width: 13.75rem;
      height: 3.125rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 0.875rem;
      color: #fff;
      padding: 0;

      &.btn-facebook {
        background-color: #306199;
      }

      &.btn-twitter {
        background-color: #26c4f1;
      }

      &.btn-pinterest {
        background-color: #e82b2d;
      }

      &.btn-more {
        background-color: $primary;
        font-size: 1.5625rem;
        padding: 0;
        width: 3.125rem;
      }
    }
  }
}
.blog-title-3 {
  font-size: 1.125rem !important;
  margin-bottom: 1.25rem !important;
}
