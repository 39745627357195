.category-banner {
  &__item {
    position: relative;

    &-content {
      position: absolute;
      left: 12.2%;
      right: 12.2%;
      bottom: -2.3125rem;
      background-color: white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 1.5rem 2rem;

      @include media-breakpoint-up(xl) {
        padding: 2.6875rem 2rem 1.625rem;
      }

      h2 {
        margin: 0;
      }
    }

    &-mark {
      background: $color-red-700;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      text-transform: uppercase;
      color: #fff;
      padding: 0.75rem 1rem 0.5rem;
      text-align: center;
      line-height: 1.5;
      position: absolute;
      font-size: 0.9375rem;
      width: 6.25rem;
      height: 6.25rem;
      border-radius: 6.25rem;
      right: 1.875rem;
      top: 1.875rem;
    }
  }
}