.example {
  color: #222 !important;
  background-color: #fff !important;
  z-index: 12234;
  //   display: none;
}
.theme-19 {
  .swiper-scrollbar-drag {
    background-color: #c32929;
  }
}
.border-top-1-dashed {
  border-top: 1px dashed !important;
}

.theme-22 {
  .color-body {
    color: #222222 !important;
    .navigation__link {
      color: #222222 !important;
    }
  }
}
.product-single__thumbnail .swiper-slide {
  opacity: 0.5;
  cursor: pointer;
}
.product-single__thumbnail .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
  cursor: pointer;
}
.item-zoom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.8125rem;
  height: 2.8125rem;
  position: absolute;
  right: 1.875rem;
  bottom: 1.875rem;
  border-radius: 2rem;
  background-color: #fff;
  transition: all 0.3s ease;
}
.swiper-thumb-3 {
  .swiper-slide {
    @media (min-width: 500px) {
      width: fit-content !important;
    }
  }
}
.swiper-thumb-4 {
  .swiper-slide {
    height: fit-content !important;
  }
}
.nav-link {
  cursor: pointer;
}

a {
  cursor: pointer;
}
.rc-slider {
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  .rc-slider-handle {
    border: solid 2px #000;
    opacity: 1 !important;

    background-color: #fff !important;

    width: 16px;
    height: 16px;
    margin-top: -7px;
    // transition: 0.3s;
    &:hover {
      border-color: #000;
    }
  }
  .rc-slider-handle-dragging {
    border-color: #000 !important;
    box-shadow: none !important;
    // transform: scale(1.3);
  }
  .rc-slider-track,
  .rc-slider-tracks {
    background-color: #000;
  }
}
.page-wrapper {
  padding-top: 90px;
  @media (max-width: 1000px) {
    padding-top: 60px;
  }
}
.page-wrapper-3 {
  @media (max-width: 991px) {
    padding-top: 60px;
  }
}
.page-wrapper-2 {
  padding-top: 40px;
  @media (max-width: 1000px) {
    padding-top: 80px;
  }
}
.hero-8 .swiper-pagination-bullet:first-child {
  margin-left: 4px !important;
}
.blog-title {
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
}
.menu-active {
  color: #c32929 !important;
}
body {
  .nav-20.theme-color .navigation__item {
    .menu-active {
      color: #c32929 !important;
    }
  }
}
body {
  .theme-22 .color-body .navigation__item {
    .menu-active {
      color: #c32929 !important;
    }
  }
}

@media (max-width: 991px) {
  .product-single__type-4 {
    max-width: 100vw;
    overflow: hidden !important;
  }
}
.product-single__addtocart.product-single__grouped .grouped-item {
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .grouped-item__price {
      margin-left: 0;
    }
  }
}
.btn-outline-primary:hover {
  a {
    color: #fff !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.product-single__thumbnail .swiper-slide {
  height: fit-content !important;
}
