:root {
  --theme-color: #c32929;

  --theme-color-secondary: #b9a16b;

  --theme-color-third: #86bc42;
  --font-heading: "Jost", sans-serif;
  --font-family-base: "Jost", sans-serif;
  --font-special: "Allura", cursive;
}
.theme-11 {
  --theme-color: #0071e3;
  --theme-color-secondary: #0046be;
}
.theme-12 {
  --theme-color: #86bc42;
  --theme-color-secondary: #074e37;
  --theme-color-third: #86bc42;
}
.theme-13 {
  --theme-color: #193174;
  --theme-color-secondary: #ebb73f;
  --theme-color-third: #86bc42;
}
.theme-1 {
  --font-heading: "Jost", sans-serif;
  --font-family-base: "Jost", sans-serif;
  --font-special: "Allura", cursive;
  --theme-color: #c32929;
  --theme-color-secondary: #b9a16b;
  --theme-color-third: #86bc42;
}
.theme-14 {
  --theme-color: #193174;
  --theme-color-secondary: #ebb73f;
  --theme-color-third: #86bc42;
  --font-heading: "Jost", sans-serif;
  --font-family-base: "Jost", sans-serif;
  --font-special: "Allura", cursive;
}
.theme-15 {
  --theme-color: #193174;
  --theme-color-secondary: #ebb73f;
  --theme-color-third: #86bc42;
  --font-heading: "Jost", sans-serif;
  --font-family-base: "Jost", sans-serif;
  --font-special: "Allura", cursive;
}
.theme-16 {
  --font-heading: "Rajdhani", "Jost", sans-serif;
  --font-family-base: "Rajdhani", "Jost", sans-serif;
  --font-special: "Allura", cursive;
  --theme-color: #ed1d24;
  --theme-color-secondary: #ebb73f;
  --theme-color-third: #86bc42;
}
.theme-17 {
  --theme-color: #142747;
  --theme-color-secondary: #b9a16b;
  --theme-color-third: #86bc42;
  --font-heading: "Cormorant Garamond", sans-serif;
  --font-family-base: "Open Sans", sans-serif;
  --font-special: "Allura", cursive;
  // display: none;
}
.theme-18 {
  --font-heading: "Exo 2", sans-serif;
  --font-family-base: "Exo 2", sans-serif;
  --font-special: "Allura", cursive;
  --theme-color: #0f2041;
  --theme-color-secondary: #f6cd46;
  --theme-color-third: #86bc42;
  font-family: var(--font-family-base);
}
.theme-19 {
  --theme-color: #c32929;
  --theme-color-secondary: #b9a16b;
  --theme-color-third: #86bc42;
  --font-heading: "Montserrat", sans-serif;
  --font-family-base: "Montserrat", sans-serif;
  --font-special: "Allura", cursive;
}
.theme-20 {
  --theme-color: #b9a16b;
  --theme-color-secondary: #b9a16b;
  --theme-color-third: #86bc42;
  --font-heading: "Lora", sans-serif;
  --font-family-base: "Jost", sans-serif;
  --font-special: "Poppins", sans-serif;
}

.theme-21 {
  --body-color-secondary: #5a5a5a; //--secondary;
  --theme-color: #193174;
  --theme-color-secondary: #e3766a;
  --theme-color-third: #615292;
  --font-heading: "Nunito", sans-serif;
  --font-family-base: "Nunito", sans-serif;
  --font-special: "Allura", cursive;
}
.theme-22 {
  --theme-color: #f09c38;
  --theme-color-secondary: #d6001c;
  --theme-color-third: #0f2041;
  --font-heading: "Jost", sans-serif;
  --font-family-base: "Jost", sans-serif;
  --font-special: "Allura", cursive;
}
.theme-23 {
  --theme-color: #0071e3;
  --theme-color-secondary: #0071e3;
  --theme-color-third: #0071e3;
  --font-heading: "Montserrat", sans-serif;
  --font-family-base: "Montserrat", sans-serif;
  --font-special: "Allura", cursive;
}
