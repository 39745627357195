@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

/// Transform
/// @param {List} $params - Params
/// @require {mixin} css3-prefix

@mixin transform($params) {
  @include css3-prefix("transform", $params);
}

/// Transform-Origin
/// @param {List} $params - Params
/// @require {mixin} css3-prefix

@mixin transform-origin($params) {
  @include css3-prefix("transform-origin", $params);
}

// Transform-Style
/// @param {List} $params - Params
/// @require {mixin} css3-prefix

@mixin transform-style($style: preserve-3d) {
  @include css3-prefix("transform-style", $style);
}

@mixin animation-delay($i) {
  transition-delay: #{calc($i / 10)}s;
}

/// Keyframes
/// @param {*} $animation-name - Animation name
/// @content [Animation css]

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

/// Animation
/// @param {*} $str - name duration timing-function delay iteration-count direction fill-mode play-state ([http://www.w3schools.com/cssref/css3_pr_animation.asp](http://www.w3schools.com/cssref/css3_pr_animation.asp))
/// @require {mixin} css3-prefix

@mixin animation($str) {
  @include css3-prefix("animation", $str);
}
